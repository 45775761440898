@import '@styles/styles.scss';

.qrCodeBoardWrapper {
    .qrCodeImg {
        padding: 10px;
        border-radius: 17px;
        background-color: $COLOR_WHITE;
        border: 3px solid $COLOR_MANDY_RED;
        box-shadow: 0 2px 10px 4px rgba(37, 39, 38, 0.05);
    }
}
