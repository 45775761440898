@import '@styles/styles.scss';

.itemField {
    &.sellerName .itemTags {
        .itemTag {
            margin-bottom: 0;
            margin-right: 0;
        }
        @include mobileLarge {
            margin-top: 8px;
        }
    }
}
