@import '@styles/styles.scss';

/*=============================================
=                 Page Templates              =
=============================================*/
.wrapper.buyPaying,
.wrapper.sellPaying {
    padding-top: 0;
}

.mainInfosWrapper {
    min-width: 800px;
    background-color: $COLOR_WHITE;

    @include tablet {
        min-width: auto;
    }
}
/*=====  End of Page Templates  ======*/

/*=============================================
=                 Buttons                     =
=============================================*/
.btnGroupWrapper {
    display: flex;
    justify-content: center;
    @include dividerTop(100%);
    &:after {
        box-shadow: 0 2px 4px 0 rgba($COLOR_BLACK, 0.2);
    }
}

.btn {
    flex: 1;
    width: 45%;
    max-width: 600px;

    @include mobile {
        width: 90%;
    }
    .content .iconAddition {
        display: none;
    }

    &.MuiButton-root,
    &.MuiButton-contained,
    &.MuiButton-outlined {
        &:hover {
            box-shadow: none;
        }
    }

    &.MuiButton-contained {
        svg .fillArea {
            fill: $primaryTextContrast;
            stroke: $primaryTextContrast;
        }

        &.Mui-disabled {
            background-color: $COLOR_IMPERIALRED;
            color: $primaryTextContrast;
            opacity: 0.5;

            .MuiButton-label {
                color: $primaryTextContrast;
            }
        }
    }

    &.MuiButton-outlined {
        border: 1px solid $COLOR_IMPERIALRED;
        box-shadow: 0 7px 13px 0 rgba($COLOR_BLACK, 0.11);
        background-color: rgba($COLOR_MIDGREY, 0.04);

        &:hover {
            box-shadow: none;
            color: $COLOR_MIDGREY;
        }

        .MuiButton-label {
            color: $primaryColor;
        }

        svg .fillArea {
            fill: $primaryColor;
        }
    }
}

.dialogActionGroup {
    &.MuiDialogActions-root {
        display: flex;
        justify-content: center;
    }
}

/*=====  End of Buttons  ======*/
.itemField.count,
.itemField.userPrice {
    display: none;
}
.itemField.unitCount,
.itemField.realAmount {
    .unit,
    .numeric {
        color: $primaryColor;
        line-height: 1;
    }
}

.qrBoardInfosWrapper {
    border: none;
    box-shadow: none;
}

.snackbarWrapper {
    &.MuiAlert-standardSuccess {
        background-color: $primaryColor;
        border: 1px solid $primaryColor;
        color: $COLOR_WHITE;

        .MuiAlert-icon {
            display: none;
        }
    }
}
