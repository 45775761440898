@import '@styles/variables.scss';

/*=============================================
=               Template Colors               =
=============================================*/
$primaryColor: $COLOR_MOJO_RED;
$primaryTextContrast: $COLOR_WHITE;
$infosBkColor: $COLOR_WHITE;
$bodyBkColor: $COLOR_WHITE;

$primaryGradient: linear-gradient(135deg, #cd4949 0%, #f6a742 100%);
/*=====  End of Template Colors  ======*/
