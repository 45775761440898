@import '@styles/styles.scss';

.resultedWrapperTwoSimple {
    .mainInfosWrapper {
        display: flex;
        flex-direction: column;
        .infosHeader {
            width: 100%;
        }

        .orderInfos {
            margin-top: 0;
        }
    }
}
