@import '@styles/styles.scss';

.stepper {
    background-color: $COLOR_WHITE;
    border: 2px solid $primaryColor;
    border-radius: 31px;
    display: flex;
    margin-bottom: 0;
    justify-content: space-between;
    width: 100%;

    .stepItm {
        border-radius: 31px;
        padding: 15px;
        color: $COLOR_BLACK;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        .stepText {
            .stepName {
                text-align: center;
                font-size: $FONTSIZE_20;
            }
        }

        &.currStep {
            color: $primaryTextContrast;
            background-color: $primaryColor;
        }
    }

    @include tablet {
        .stepItm {
            .stepText {
                .stepName {
                    font-size: $FONTSIZE_16;
                }
            }
        }
    }

    @include mobileLarge {
        .stepItm {
            width: 100%;
            padding: 10px;
        }

        .iconChevronRight {
            display: none;
        }
    }

    @include mobileSmall {
        .stepItm {
            padding: 5px;
            .stepText {
                .stepName {
                    font-size: $FONTSIZE_BODY;
                }
            }
        }
    }
}
