@import '@styles/styles.scss';

.infosHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 20px;
    padding: 50px;

    @include desktop {
        width: 50%;
    }

    @include mobile {
        padding: 40px 0 20px;

        .headline {
            .title {
                font-size: $FONTSIZE_30;
            }
        }
    }

    .headline {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        .title {
            margin-top: 40px;
            margin-bottom: 20px;
            font-size: $FONTSIZE_40;
            text-align: center;

            @include tablet {
                margin-top: 20px;
            }
        }

        .iconHead {
            width: 120px;
            height: 120px;

            @include tablet {
                width: 100px;
                height: 100px;
            }

            @include mobile {
                width: 80px;
                height: 80px;
            }
        }
    }

    .message {
        font-size: $FONTSIZE_18;
        text-align: center;
    }
}
