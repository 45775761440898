@import '@styles/styles.scss';

.itemTagGroupMerged {
    border: none;
    margin-top: 5px;
    background: $primaryGradient;

    .itemTag {
        button {
            color: $primaryTextContrast;
            background-color: transparent;

            &.MuiButton-containedSecondary:hover {
                background-color: transparent;
            }
        }

        & + .itemTag {
            &:before {
                color: $primaryTextContrast;
            }
        }
    }
}
