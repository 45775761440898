@import '@styles/styles.scss';

.btnCopyWrapper {
    .txtCopy {
        display: none;
    }

    .btn.btnCopy {
        &.MuiButton-text {
            color: $COLOR_BLACK;
            height: 26px;
            width: 26px;
            min-width: 26px;
            padding: 0;
            &:hover {
                background-color: transparent;
            }
            .iconCopy:hover {
                background-color: $COLOR_GAINSBORO_GREY;
            }
        }
    }
}
