@import '@styles/styles.scss';

button.btn.btnResend {
    border-radius: 25px;
    background-color: $COLOR_WHITE;

    &:hover {
        background-color: $COLOR_GHOST_WHITE;
    }
    .countDownSecondTxt,
    .text {
        color: $primaryColor;
    }
}
