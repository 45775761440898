@import '@styles/styles.scss';

.resultedWrapper {
    .orderInfos {
        margin-top: 38px;
        margin-left: 30px;

        @include mobileLarge {
            margin-left: 0;
        }

        .infosTitle {
            display: none;
        }

        &.AmountInfos {
            .infosWrapper {
                @include desktop {
                    padding-top: 20px;
                }
                padding-top: 0;
            }
        }

        &.amountInfosWrapper {
            .leftInfos {
                .itemFieldList {
                    display: flex;
                    flex-direction: column;
                }
            }

            @include tablet {
                width: 100%;
            }
        }
    }
}
